import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { translations } from "./translations";

loadFonts();

// Create a reactive state for the language
const state = reactive({
  locale: "no", // Set Norwegian as the default language
  translations,
});

const app = createApp(App);

// Provide the state to the app
app.provide("state", state);

app.use(vuetify).use(router).mount("#app");
