<template>
  <v-container class="easiest-hardest-container">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="pa-4 elevation-2">
          <v-card-title class="headline mb-2">
            {{ t("message.easiestHardestTitle") }}
          </v-card-title>
          <v-card-text>
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <v-col cols="12" md="12" class="mb-3">
                <SearchBar
                  :items="institutions"
                  dataType="institutions"
                  @search="onInstitutionSelect"
                />
              </v-col>
              <v-col cols="12" md="12" class="mb-3" v-if="selectedInstitution">
                <SearchBar
                  :items="studyPrograms"
                  dataType="studyPrograms"
                  @search="onStudyProgramSelect"
                />
              </v-col>
            </v-row>
            <v-row v-if="easiestCourses.length || hardestCourses.length">
              <v-col cols="12" md="6">
                <h3 class="course-heading">
                  {{ t("message.easiestCourses") }}
                </h3>
                <ul class="course-list">
                  <li
                    v-for="course in easiestCourses"
                    :key="course.course_code"
                    class="course-list-item"
                    @click="navigateToCourse(course)"
                  >
                    <span class="course-code">{{ course.course_code }}</span> -
                    <span class="course-name">{{ course.course_name }}</span>
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" md="6">
                <h3 class="course-heading">
                  {{ t("message.hardestCourses") }}
                </h3>
                <ul class="course-list">
                  <li
                    v-for="course in hardestCourses"
                    :key="course.course_code"
                    class="course-list-item"
                    @click="navigateToCourse(course)"
                  >
                    <span class="course-code">{{ course.course_code }}</span> -
                    <span class="course-name">{{ course.course_name }}</span>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "@/ApiService";
import SearchBar from "@/components/SearchBar.vue";
import { inject } from "vue";

export default {
  name: "EasiestHardestCoursesPage",
  components: {
    SearchBar,
  },
  setup() {
    const state = inject("state");

    const t = (key) => {
      const keys = key.split(".");
      let value = state.translations[state.locale];
      keys.forEach((k) => {
        value = value[k];
      });
      return value;
    };

    return { t };
  },
  data() {
    return {
      institutions: [],
      studyPrograms: [],
      selectedInstitution: null,
      selectedStudyProgram: null,
      easiestCourses: [],
      hardestCourses: [],
    };
  },
  created() {
    this.fetchInstitutions();
  },
  methods: {
    async fetchInstitutions() {
      try {
        const response = await ApiService.getInstitutions();
        this.institutions = response.data;
      } catch (error) {
        console.error("Error fetching institutions:", error);
      }
    },
    async fetchStudyPrograms(institutionCode) {
      try {
        const response = await ApiService.getStudyPrograms(institutionCode);
        this.studyPrograms = response.data;
      } catch (error) {
        console.error("Error fetching study programs:", error);
      }
    },
    async fetchCourses() {
      if (!this.selectedInstitution) return;
      try {
        const easiestResponse = await ApiService.getEasiestCourses(
          this.selectedInstitution,
          this.selectedStudyProgram
        );
        const hardestResponse = await ApiService.getHardestCourses(
          this.selectedInstitution,
          this.selectedStudyProgram
        );
        this.easiestCourses = easiestResponse.data;
        this.hardestCourses = hardestResponse.data;
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    },
    onInstitutionSelect(institution) {
      if (institution && institution.code) {
        this.selectedInstitution = institution.code;
        this.selectedStudyProgram = null; // Reset study program selection
        this.fetchStudyPrograms(institution.code);
        this.fetchCourses();
      } else {
        console.error("Invalid institution selected:", institution);
      }
    },
    onStudyProgramSelect(studyProgram) {
      if (studyProgram && studyProgram.code) {
        this.selectedStudyProgram = studyProgram.code;
        this.fetchCourses();
      } else {
        console.error("Invalid study program selected:", studyProgram);
      }
    },
    navigateToCourse(course) {
      this.$router.push({
        name: "homeWithCourse",
        params: { courseCode: course.course_code },
      });
    },
  },
};
</script>

<style scoped>
.easiest-hardest-container {
  overflow: visible;
}

.v-card {
  overflow: visible;
}

.v-card-title {
  font-weight: bold;
  font-size: 2rem;
  color: #343a40;
}

p {
  line-height: 1.6;
  color: #495057;
}

.left-align {
  text-align: left;
}

.v-divider {
  margin-bottom: 20px;
}

.course-heading {
  font-weight: bold;
  color: #3f51b5;
}

.course-list {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.course-list-item {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.course-list-item:hover {
  background-color: #f0f0f0;
}

.course-code {
  font-weight: bold;
  color: #3f51b5;
}

.course-name {
  color: #495057;
}

@media (max-width: 600px) {
  .v-card-title {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.9rem;
  }
}
</style>
