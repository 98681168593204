<template>
  <div class="chart-container">
    <canvas ref="chartCanvas" :key="canvasKey"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";

export default {
  name: "ChartComponent",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartInstance: null,
      canvasKey: 0, // unique key for canvas
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeChart();
    });
  },
  methods: {
    initializeChart() {
      Chart.register(...registerables);
      this.createChart();
    },
    createChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
      this.$nextTick(() => {
        const canvas = this.$refs.chartCanvas;
        if (canvas) {
          const ctx = canvas.getContext("2d");
          if (ctx) {
            this.chartInstance = new Chart(ctx, {
              type: "bar",
              data: this.chartData,
              options: {
                ...this.chartOptions,
                responsive: true,
                maintainAspectRatio: false,
              },
            });
          }
        }
      });
    },
  },
  watch: {
    chartData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.canvasKey++; // update key to force re-render of canvas
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
    chartOptions: {
      deep: true,
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$nextTick(() => {
            this.canvasKey++; // update key to force re-render of canvas
            this.createChart();
          });
        }
      },
    },
  },
  beforeUnmount() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  },
};
</script>

<style>
.chart-container {
  width: 100%;
  height: 40vh;
  margin: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .chart-container {
    max-height: 40vh; /* Adjust to fit the screen minus some space for headers/footers */
    padding: 0;
    box-shadow: none;
  }
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
