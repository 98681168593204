import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class ApiService {
  // Get a list of courses
  getCourses() {
    return axios.get(`${API_URL}/courses`);
  }

  // Get detailed information about a specific course by course_code
  getCourseDetails(courseCode) {
    return axios.get(`${API_URL}/course/${courseCode}`);
  }

  getAggregatedCourseStats(courseCode) {
    return axios.get(`${API_URL}/course/${courseCode}/aggregated_stats`);
  }

  // Get course offerings by course_code
  getCourseOfferings(courseCode) {
    return axios.get(`${API_URL}/course/${courseCode}/offerings`);
  }

  // Get offering grades by offering_id
  getOfferingGrades(offeringId) {
    return axios.get(`${API_URL}/offering/${offeringId}/grades`);
  }

  // Get top courses based on a statistic and a limit
  getTopCourses(statistic, limit) {
    return axios.get(`${API_URL}/top_courses/${statistic}/${limit}`);
  }

  // Get overall adjusted difficulty statistics
  getOverallAdjustedDifficulty() {
    return axios.get(`${API_URL}/statistics/adjusted_difficulty`);
  }

  // Get common courses for a specific year
  getCommonCourses(year) {
    return axios.get(`${API_URL}/statistics/common_courses/${year}`);
  }

  // Get course performance by course_code
  getCoursePerformance(courseCode) {
    return axios.get(`${API_URL}/course/${courseCode}/performance`);
  }

  getInstitutions() {
    return axios.get(`${API_URL}/institutions`);
  }

  getStudyPrograms(institutionCode) {
    return axios.get(
      `${API_URL}/institutions/${institutionCode}/study_programs`
    );
  }

  getEasiestCourses(institutionCode, studyProgramCode = "") {
    let url = `${API_URL}/easiest_courses/${institutionCode}`;
    if (studyProgramCode) {
      url += `/${studyProgramCode}`;
    }
    return axios.get(url);
  }

  getHardestCourses(institutionCode, studyProgramCode = "") {
    let url = `${API_URL}/hardest_courses/${institutionCode}`;
    if (studyProgramCode) {
      url += `/${studyProgramCode}`;
    }
    return axios.get(url);
  }
}

export default new ApiService();
