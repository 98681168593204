<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="pa-4 elevation-2">
          <v-card-title class="headline mb-2">
            {{ t("message.aboutTitle") }}
          </v-card-title>
          <v-card-text>
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <v-col cols="12" class="mb-3">
                <p class="left-align">{{ t("message.aboutDescription1") }}</p>
              </v-col>
              <v-col cols="12" class="mb-3">
                <p class="left-align">{{ t("message.aboutDescription2") }}</p>
              </v-col>
              <v-col cols="12">
                <p class="left-align">{{ t("message.aboutDescription3") }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { inject } from "vue";

export default {
  name: "AboutPage",
  setup() {
    const state = inject("state");

    const t = (key) => {
      const keys = key.split(".");
      let value = state.translations[state.locale];
      keys.forEach((k) => {
        value = value[k];
      });
      return value;
    };

    return { t };
  },
};
</script>

<style scoped>
.v-card-title {
  font-weight: bold;
  font-size: 2rem;
  color: #343a40;
}

p {
  line-height: 1.6;
  color: #495057;
}

.left-align {
  text-align: left;
}

.v-divider {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .v-card-title {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.9rem;
  }
}
</style>
