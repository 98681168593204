export const translations = {
  en: {
    title: "OnlyGrades",
    message: {
      home: "Home",
      premium: "Premium",
      about: "About",
      easiestHardestCourses: "Easiest/Hardest Courses",
      aboutTitle: "About OnlyGrades",
      aboutDescription1:
        "OnlyGrades is a comprehensive platform designed to help students find detailed grade statistics for their courses. Our mission is to provide valuable insights into course performance, helping students make informed decisions about their education.",
      aboutDescription2:
        "Whether you are looking for the easiest courses, detailed grade distributions, or historical performance data, OnlyGrades has you covered.",
      aboutDescription3:
        "If you have any questions or need further assistance, please let us know.",
      homeWelcome: "Welcome to OnlyGrades",
      homeSubtitle: "Find detailed grade statistics for your courses.",
      chartHistorical: "Historical View",
      chartDistribution: "Grade Distribution",
      chartFailRates: "Historical Fail Rates",
      excludeFails: "Exclude Fails from Average",
      includeFails: "Include Fails in Average",
      selectAverageOption: "Select Average Grade Calculation",
      showFemaleStats: "Show Female Stats",
      hideFemaleStats: "Hide Female Stats",
      womenStats: "Women",
      semesters: {
        winter: "Winter",
        spring: "Spring",
        summer: "Summer",
        fall: "Fall",
      },
      search: {
        placeholder: "Search courses...",
        searchInstitution: "Search institutions...",
        searchStudyProgram: "Search study programs...",
      },
      easiestHardestTitle: "Easiest and Hardest Courses",
      easiestCourses: "Easiest Courses",
      hardestCourses: "Hardest Courses",
      allRightsReserved: "All Rights Reserved",
    },
  },
  no: {
    title: "OnlyGrades",
    message: {
      home: "Hjem",
      premium: "Premium",
      about: "Om",
      easiestHardestCourses: "Enkleste/Vanskeligste Fag",
      aboutTitle: "Om OnlyGrades",
      aboutDescription1:
        "OnlyGrades er en omfattende plattform designet for å hjelpe studenter med å finne detaljerte karakterstatistikker for Fagene sine. Vårt mål er å gi verdifulle innsikter i Fagytelse, slik at studentene kan ta informerte beslutninger om utdanningen sin.",
      aboutDescription2:
        "Enten du leter etter de enkleste Fagene, detaljerte karakterfordelinger eller historiske ytelsesdata, har OnlyGrades det du trenger.",
      aboutDescription3:
        "Hvis du har spørsmål eller trenger ytterligere assistanse, vennligst gi oss beskjed.",
      homeWelcome: "Velkommen til OnlyGrades",
      homeSubtitle: "Finn detaljerte karakterstatistikker for fagene dine.",
      chartHistorical: "Historisk Visning",
      chartDistribution: "Karakterfordeling",
      chartFailRates: "Historiske Strykprosent",
      excludeFails: "Ekskluder stryk fra gjennomsnitt",
      includeFails: "Inkluder stryk i gjennomsnitt",
      selectAverageOption: "Velg beregning for gjennomsnittskarakter",
      showFemaleStats: "Vis Kvinnelig Statistikk",
      hideFemaleStats: "Skjul Kvinnelig Statistikk",
      womenStats: "Kvinner",
      semesters: {
        winter: "Vinter",
        spring: "Vår",
        summer: "Sommer",
        fall: "Høst",
      },
      search: {
        placeholder: "Søk etter fag...",
        searchInstitution: "Søk etter institusjoner...",
        searchStudyProgram: "Søk etter studieprogrammer...",
      },
      easiestHardestTitle: "Enkleste og Vanskeligste Fag",
      easiestCourses: "Enkleste Fag",
      hardestCourses: "Vanskeligste Fag",
      allRightsReserved: "Alle Rettigheter Forbeholdt",
    },
  },
};
