import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./views/Home.vue";
import AboutPage from "./views/AboutPage.vue";
import EasiestHardestCoursesPage from "./views/EasiestHardestCoursesPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/course/:courseCode?",
    name: "homeWithCourse",
    component: HomePage,
    props: true,
  },
  {
    path: "/about",
    name: "about",
    component: AboutPage,
  },
  {
    path: "/easiest-hardest-courses",
    name: "EasiestHardestCourses",
    component: EasiestHardestCoursesPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
