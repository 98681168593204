<template>
  <div class="app-container">
    <v-app>
      <v-app-bar app color="white" dark>
        <v-toolbar-title class="title">{{ t("title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text to="/">{{ t("message.home") }}</v-btn>
        <v-btn text to="/easiest-hardest-courses">{{
          t("message.easiestHardestCourses")
        }}</v-btn>
        <v-btn text to="/about">{{ t("message.about") }}</v-btn>
      </v-app-bar>
      <v-main class="main-content">
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
      <v-footer app color="transparent" dark>
        <v-row class="w-100" justify="space-between" align="center" no-gutters>
          <v-col class="text-left">
            © 2024 OnlyGrades - {{ t("message.allRightsReserved") }}
          </v-col>
          <v-col class="text-right">
            <v-btn-toggle
              v-model="selectedLanguage"
              @change="changeLanguage"
              mandatory
            >
              <v-btn value="en" outlined color="white">English</v-btn>
              <v-btn value="no" outlined color="white">Norsk</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import { inject, ref, watch } from "vue";

export default {
  name: "App",
  setup() {
    const state = inject("state");
    const selectedLanguage = ref(state.locale);

    const t = (key) => {
      const keys = key.split(".");
      let value = state.translations[state.locale];
      keys.forEach((k) => {
        value = value[k];
      });
      return value;
    };

    const changeLanguage = (lang) => {
      state.locale = lang;
      document.title = t("title");
    };

    watch(selectedLanguage, (newLang) => {
      changeLanguage(newLang);
    });

    return { t, state, selectedLanguage, changeLanguage };
  },
  watch: {
    $route() {
      document.title = this.t("title");
    },
  },
  mounted() {
    document.title = this.t("title");
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  font-size: 2rem;
  color: #343a40;
}

.v-footer {
  padding: 16px;
}

.v-btn {
  margin: 0 4px;
}

.v-btn-toggle {
  display: inline-flex;
}

.v-toolbar-title {
  font-size: 1.5rem;
}

.v-app-bar {
  box-shadow: none;
}

.main-content {
  flex: 1;
}

@media (max-width: 600px) {
  .title {
    font-size: 1.5rem;
  }
  .v-toolbar-title {
    font-size: 1.2rem;
  }
  .v-btn {
    font-size: 0.8rem;
  }
  .v-footer {
    padding: 8px;
  }
}
</style>
