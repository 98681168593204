<template>
  <div class="home-container">
    <div v-if="showHero" class="hero">
      <div class="hero-content">
        <h1 class="hero-title">{{ t("message.homeWelcome") }}</h1>
        <p class="hero-subtitle">{{ t("message.homeSubtitle") }}</p>
        <SearchBar
          :items="allCourses"
          @search="handleSearch"
          :key="search_rerender_key"
          class="hero-search-bar"
        />
      </div>
    </div>
    <div v-if="searchPerformed" class="search-bar-container search-bar-top">
      <SearchBar
        :items="allCourses"
        @search="handleSearch"
        :key="search_rerender_key"
        class="search-bar-top"
      />
    </div>

    <section
      v-if="courseSelected && searchResults.length"
      class="result-section"
    >
      <v-row justify="center">
        <v-col
          v-for="course in searchResults"
          :key="course.course_code"
          cols="12"
          md="8"
        >
          <v-card-title>{{ course.course_name }}</v-card-title>
          <v-card-subtitle>{{ course.course_code }}</v-card-subtitle>
          <ChartComponent
            :chartData="chartData"
            :chartOptions="chartOptionsComp"
            :chartType="chartType"
            class="chart"
          />
        </v-col>
      </v-row>
    </section>

    <div v-if="courseSelected" class="options-container">
      <div class="chart-modes">
        <button
          @click="setChartMode('historical')"
          :class="{ active: chartMode === 'historical' }"
        >
          <v-icon left>mdi-chart-line</v-icon>
          {{ t("message.chartHistorical") }}
        </button>
        <button
          @click="setChartMode('distribution')"
          :class="{ active: chartMode === 'distribution' }"
        >
          <v-icon left>mdi-chart-pie</v-icon>
          {{ t("message.chartDistribution") }}
        </button>
        <button
          @click="setChartMode('failRates')"
          :class="{ active: chartMode === 'failRates' }"
        >
          <v-icon left>mdi-chart-bar</v-icon>
          {{ t("message.chartFailRates") }}
        </button>
      </div>

      <div v-if="chartMode === 'distribution'" class="dropdown-container">
        <select
          v-model="selectedYear"
          @change="updateAvailableSemesters"
          class="dropdown"
        >
          <option v-for="year in availableYears" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
        <select v-model="selectedSemester" class="dropdown">
          <option
            v-for="semester in availableSemesters"
            :key="semester.value"
            :value="semester.value"
          >
            {{ t(`message.semesters.${semester.text}`) }}
          </option>
        </select>
      </div>

      <div class="toggle-buttons">
        <button
          @click="excludeFails = !excludeFails"
          :class="{ active: excludeFails }"
          class="toggle-button"
          v-if="chartMode === 'historical'"
        >
          {{
            excludeFails ? t("message.includeFails") : t("message.excludeFails")
          }}
        </button>
        <button
          @click="showFemaleStats = !showFemaleStats"
          :class="{ active: showFemaleStats }"
          class="toggle-button"
        >
          {{
            showFemaleStats
              ? t("message.hideFemaleStats")
              : t("message.showFemaleStats")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useRoute } from "vue-router";
import ApiService from "@/ApiService";
import SearchBar from "@/components/SearchBar.vue";
import ChartComponent from "@/components/ChartComponent.vue";
import LZString from "lz-string";

const CACHE_KEY = "allCourses";
const TIMESTAMP_KEY = "coursesTimestamp";
const CACHE_DURATION = 28 * 24 * 60 * 60 * 1000; // 28 days in milliseconds

export default {
  name: "HomePage",
  components: {
    SearchBar,
    ChartComponent,
  },
  setup() {
    const state = inject("state");
    const route = useRoute();

    const t = (key) => {
      const keys = key.split(".");
      let value = state.translations[state.locale];
      keys.forEach((k) => {
        value = value[k];
      });
      return value;
    };

    return { t, route };
  },
  data() {
    return {
      allCourses: [], // This stores the original list of courses
      searchResults: [], // This stores the filtered list for the search
      searchPerformed: false,
      initialSearchDone: false,
      chartMode: "historical", // Default chart mode
      selectedYear: null,
      selectedSemester: null,
      availableYears: [],
      availableSemesters: [],
      excludeFails: true, // Default to exclude fails
      showFemaleStats: false, // Default to show female stats
      search_rerender_key: 0,
    };
  },
  created() {
    this.loadCourses(); // Load courses from local storage or API
  },
  mounted() {
    const courseCode = this.route.params.courseCode;
    if (courseCode) {
      this.handleSearch({ course_code: courseCode });
    }
  },
  methods: {
    async loadCourses() {
      const cachedCourses = localStorage.getItem(CACHE_KEY);
      const timestamp = localStorage.getItem(TIMESTAMP_KEY);
      const now = Date.now();
      if (
        cachedCourses &&
        timestamp &&
        now - parseInt(timestamp) < CACHE_DURATION
      ) {
        const parsedCourses = JSON.parse(LZString.decompress(cachedCourses));
        this.allCourses = parsedCourses;
        this.searchResults = parsedCourses;
      } else {
        await this.fetchCourses();
      }
    },
    async fetchCourses() {
      try {
        const response = await ApiService.getCourses();
        const { course_codes, course_names } = response.data;
        const courses = course_codes.map((code, index) => ({
          course_code: code,
          course_name: course_names[index],
        }));
        this.allCourses = courses;
        this.searchResults = courses;
        localStorage.setItem(
          CACHE_KEY,
          LZString.compress(JSON.stringify(courses))
        );
        localStorage.setItem(TIMESTAMP_KEY, Date.now().toString());
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    },
    async handleSearch(courseObject) {
      try {
        const courseCode = courseObject.course_code;
        this.searchPerformed = false;
        if (!this.initialSearchDone) {
          this.initialSearchDone = true;
        }
        await this.handleDirectCourseSearch(courseCode);
        this.search_rerender_key++; // Increment the key to force re-render of the search bar
      } catch (error) {
        console.error("Error fetching course stats:", error);
        this.searchPerformed = false;
      }
      // Update available years and set defaults for year and semester
      this.updateAvailableYears();
      if (this.availableYears.length > 0) {
        this.selectedYear = this.availableYears[this.availableYears.length - 1];
        this.updateAvailableSemesters();
      } else {
        this.selectedYear = null;
        this.selectedSemester = null;
      }
    },
    async handleDirectCourseSearch(courseCode) {
      try {
        const response = await ApiService.getAggregatedCourseStats(courseCode);
        this.searchResults = [response.data]; // Update with the searched course's data
        this.searchPerformed = true;
      } catch (error) {
        console.error("Error fetching course stats:", error);
        this.searchPerformed = false;
      }
    },
    setChartMode(mode) {
      this.chartMode = mode;
      // Trigger a redraw of the chart with new data
    },
    getHistoricalViewData(aggregatedData) {
      const semesterLabels = [
        this.t("message.semesters.winter"),
        this.t("message.semesters.spring"),
        this.t("message.semesters.summer"),
        this.t("message.semesters.fall"),
      ];
      const averageGradeKey = this.excludeFails
        ? "average_grade_no_fails"
        : "average_grade";
      const averageGradeWomenKey = this.excludeFails
        ? "average_grade_women_no_fails"
        : "average_grade_women";
      const datasets = [
        {
          label: this.t("message.chartHistorical"),
          data: aggregatedData.map((d) => d[averageGradeKey]),
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          borderColor: "rgb(54, 162, 235)",
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          type: "line",
        },
      ];

      if (this.showFemaleStats) {
        datasets.push({
          label:
            this.t("message.chartHistorical") +
            ` (${this.t("message.womenStats")})`,
          data: aggregatedData.map((d) => d[averageGradeWomenKey]),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          type: "line",
        });
      }

      return {
        labels: aggregatedData.map(
          (d) => `${d.year} - ${semesterLabels[d.semester]}`
        ),
        datasets,
      };
    },
    getDistributionViewData(aggregatedData, year, semester) {
      const distributionData = aggregatedData.find(
        (d) => d.year == year && d.semester == semester
      );
      if (!distributionData) return { labels: [], datasets: [] };
      const isPassFail =
        !distributionData.grade_distribution.A &&
        !distributionData.grade_distribution.B &&
        !distributionData.grade_distribution.C &&
        !distributionData.grade_distribution.D &&
        !distributionData.grade_distribution.E &&
        !distributionData.grade_distribution.F;

      const labels = isPassFail
        ? ["Pass", "Fail"]
        : Object.keys(distributionData.grade_distribution);
      const data = isPassFail
        ? [
            distributionData.pass_rate * distributionData.total_students,
            (1 - distributionData.pass_rate) * distributionData.total_students,
          ]
        : Object.values(distributionData.grade_distribution);

      const dataWomen = isPassFail
        ? [
            distributionData.pass_rate_women * distributionData.total_women,
            (1 - distributionData.pass_rate_women) *
              distributionData.total_women,
          ]
        : Object.values(distributionData.gender_grade_distribution);

      const colors = this.generateColors(labels.length);
      const borderColors = this.generateColors(labels.length, true);

      const datasets = [
        {
          label: this.t("message.chartDistribution"),
          data,
          backgroundColor: colors,
          borderColor: borderColors,
          borderWidth: 1,
          tension: 0.4,
        },
      ];

      if (this.showFemaleStats) {
        datasets.push({
          label:
            this.t("message.chartDistribution") +
            ` (${this.t("message.womenStats")})`,
          data: dataWomen,
          backgroundColor: colors.map((color) =>
            this.adjustColorBrightness(color, -20)
          ),
          borderColor: borderColors,
          borderWidth: 1,
          tension: 0.4,
        });
      }

      return {
        labels,
        datasets,
      };
    },
    getFailRatesViewData(aggregatedData) {
      const semesterLabels = [
        this.t("message.semesters.winter"),
        this.t("message.semesters.spring"),
        this.t("message.semesters.summer"),
        this.t("message.semesters.fall"),
      ];
      const datasets = [
        {
          label: this.t("message.chartFailRates"),
          data: aggregatedData.map((d) => 1 - d.pass_rate),
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          type: "line",
        },
      ];

      if (this.showFemaleStats) {
        datasets.push({
          label:
            this.t("message.chartFailRates") +
            ` (${this.t("message.womenStats")})`,
          data: aggregatedData.map((d) => 1 - d.pass_rate_women),
          backgroundColor: "rgba(255, 159, 64, 0.5)",
          borderColor: "rgb(255, 159, 64)",
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          type: "line",
        });
      }

      return {
        labels: aggregatedData.map(
          (d) => `${d.year} - ${semesterLabels[d.semester]}`
        ),
        datasets,
      };
    },
    updateAvailableYears() {
      if (
        this.searchResults.length > 0 &&
        this.searchResults[0].aggregated_data
      ) {
        const years = new Set(
          this.searchResults[0].aggregated_data.map((data) => data.year)
        );
        this.availableYears = Array.from(years).sort();
      }
    },
    updateAvailableSemesters() {
      if (this.selectedYear && this.courseSelected) {
        const aggregatedData = this.searchResults[0].aggregated_data;
        const semesters = [
          { text: "winter", value: 0 },
          { text: "spring", value: 1 },
          { text: "summer", value: 2 },
          { text: "fall", value: 3 },
        ].filter((semester) =>
          aggregatedData.some(
            (data) =>
              data.year == this.selectedYear && data.semester == semester.value
          )
        );
        this.availableSemesters = semesters;
        if (semesters.length > 0) {
          this.selectedSemester = semesters[0].value;
        } else {
          this.selectedSemester = null;
        }
      }
    },
    generateColors(count, border = false) {
      const colors = [];
      for (let i = 0; i < count; i++) {
        const color = `hsl(${(i / count) * 360}, 70%, ${
          border ? "50%" : "75%"
        })`;
        colors.push(color);
      }
      return colors;
    },
    adjustColorBrightness(color, amount) {
      const usePound = color[0] === "#";
      const num = parseInt(color.slice(1), 16);
      const r = Math.max(Math.min((num >> 16) + amount, 255), 0);
      const g = Math.max(Math.min((num >> 8) & (0x00ff + amount), 255), 0);
      const b = Math.max(Math.min((num & 0x0000ff) + amount, 255), 0);
      return (
        (usePound ? "#" : "") +
        (0x1000000 + r * 0x10000 + g * 0x100 + b).toString(16).slice(1)
      );
    },
  },
  computed: {
    showHero() {
      return !this.initialSearchDone;
    },
    courseSelected() {
      return (
        this.searchResults.length > 0 && this.searchResults[0].aggregated_data
      );
    },
    chartData() {
      const aggregatedData = this.searchResults[0]?.aggregated_data || [];
      switch (this.chartMode) {
        case "historical":
          return this.getHistoricalViewData(aggregatedData);
        case "distribution":
          return this.getDistributionViewData(
            aggregatedData,
            this.selectedYear,
            this.selectedSemester
          );
        case "failRates":
          return this.getFailRatesViewData(aggregatedData);
        default:
          return this.getHistoricalViewData(aggregatedData);
      }
    },
    chartOptionsComp() {
      const chart_mode = this.chartMode;
      const legendDisplay = this.chartMode !== "distribution";
      const scales =
        this.chartMode === "historical"
          ? {
              y: {
                beginAtZero: true,
                min: 0,
                max: 5,
                ticks: {
                  callback: function (value) {
                    if (Number.isInteger(value)) {
                      const gradeLabels = ["F", "E", "D", "C", "B", "A"];
                      return gradeLabels[value] + " (" + value + ")";
                    }
                    return null;
                  },
                  stepSize: 1,
                },
              },
            }
          : this.chartMode === "failRates"
          ? {
              y: {
                beginAtZero: true,
                max: 1,
                ticks: {
                  callback: function (value) {
                    return (value * 100).toFixed(0) + "%";
                  },
                },
              },
            }
          : {
              y: {
                beginAtZero: true,
              },
            };
      return {
        responsive: true,
        scales,
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                const label = context.dataset.label || "";
                const value = context.raw;
                if (chart_mode === "failRates") {
                  return `${label}: ${(value * 100).toFixed(2)}%`;
                } else if (chart_mode === "distribution") {
                  const total = context.dataset.data.reduce(
                    (acc, val) => acc + val,
                    0
                  );
                  const percentage = ((value / total) * 100).toFixed(2);
                  return `${label}: ${value} (${percentage}%)`;
                } else {
                  return `${label}: ${value}`;
                }
              },
            },
          },
          legend: {
            display: legendDisplay,
          },
        },
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
      };
    },
    chartType() {
      return this.chartMode === "distribution" ? "bar" : "line";
    },
  },
};
</script>

<style scoped>
.home-container {
  max-width: 1200px;
  margin: auto;
  padding: 0rem;
}

.hero {
  background: linear-gradient(150deg, #425671 0%, #3b7a6c 100%);
  color: #333;
  padding: 4rem 0;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: heroEnter 1s ease-out;
}

.hero-content {
  text-align: center;
}

.hero-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.hero-search-bar {
  margin: 0 auto;
  max-width: 600px;
  padding: 0.75rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.hero-search-bar:hover {
  transform: scale(1.05);
}

.search-bar-container {
  margin-bottom: 1rem;
  text-align: center;
}

.search-bar-top {
  margin: 0 auto;
  max-width: 600px;
  padding: 0.2rem;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  background: transparent;
  box-shadow: none;
}

.options-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.chart-modes {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  animation: fadeIn 1s ease-out;
  flex-wrap: wrap;
}

.chart-modes button {
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  transition: background-color 0.3s, transform 0.3s;
}

.chart-modes button.active {
  background-color: #495057;
}

.chart-modes button:hover {
  background-color: #495057;
  transform: scale(1.1);
}

.dropdown-container {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  animation: fadeIn 1s ease-out;
  flex-wrap: wrap;
}

.dropdown {
  margin: 0.25rem;
  padding: 0.25rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: box-shadow 0.3s;
}

.dropdown:focus {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.2);
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.toggle-button {
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s, transform 0.3s;
}

.toggle-button.active {
  background-color: #495057;
}

.toggle-button:hover {
  background-color: #495057;
  transform: scale(1.1);
}

.result-section {
  margin-top: 1rem;
}

.chart {
  margin: 0 auto 1rem;
  padding: 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-out;
  max-width: 100%;
}

@keyframes heroEnter {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

@media (max-width: 600px) {
  .home-container {
    padding: 0.5rem;
  }
  .chart-modes {
    flex-direction: column;
    align-items: center;
  }
  .chart-modes button {
    width: 100%;
    max-width: 200px;
    margin: 0.25rem 0;
  }
  .dropdown-container {
    flex-direction: column;
    align-items: center;
  }
  .dropdown {
    width: 100%;
    max-width: 200px;
    margin: 0.25rem 0;
  }
  .toggle-buttons {
    flex-direction: column;
    align-items: center;
  }
  .toggle-button {
    width: 100%;
    max-width: 200px;
    margin: 0.25rem 0;
  }
  .result-section {
    padding: 0.5rem;
  }
  .chart {
    height: calc(
      100vh - 120px
    ); /* Adjust to fit the screen minus some space for headers/footers */
    padding: 0;
  }
}
</style>
