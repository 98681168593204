<template>
  <div class="search-bar">
    <input
      v-model="searchTerm"
      @input="onInput"
      @keydown.enter="onEnter"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      type="search"
      class="search-input"
      :placeholder="computedPlaceholder"
      autocomplete="off"
      :aria-label="computedPlaceholder"
    />
    <ul
      v-if="visibleItems.length > 0"
      class="search-list"
      role="listbox"
      aria-labelledby="search-input"
    >
      <li
        v-for="(item, index) in visibleItems"
        :key="item.id"
        @click="selectItem(item)"
        @mouseenter="onMouseEnter(index)"
        :class="{ 'search-list-item-active': index === highlightedIndex }"
        class="search-list-item"
        role="option"
        :aria-selected="index === highlightedIndex"
      >
        {{ formatItem(item) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { inject, computed } from "vue";

export default {
  name: "SearchBar",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    dataType: {
      type: String,
      default: "courses", // or "institutions", "studyPrograms"
    },
  },
  setup(props) {
    const state = inject("state");

    const t = (key) => {
      const keys = key.split(".");
      let value = state.translations[state.locale];
      keys.forEach((k) => {
        value = value[k];
      });
      return value;
    };

    const computedPlaceholder = computed(() => {
      switch (props.dataType) {
        case "institutions":
          return t("message.search.searchInstitution");
        case "studyPrograms":
          return t("message.search.searchStudyProgram");
        default:
          return t("message.search.placeholder");
      }
    });

    return { t, computedPlaceholder };
  },
  data() {
    return {
      searchTerm: "",
      filteredItems: [],
      highlightedIndex: -1,
      itemCache: {},
      visibleItems: [],
    };
  },
  created() {
    this.debouncedFilterItems = debounce(this.filterItems, 150);
  },
  methods: {
    onInput() {
      this.debouncedFilterItems(this.searchTerm);
    },
    filterItems(term) {
      if (this.itemCache[term]) {
        this.filteredItems = this.itemCache[term];
      } else {
        const lowerCaseTerm = term.toLowerCase();
        this.filteredItems = this.items.filter((item) =>
          this.filterFunction(item, lowerCaseTerm)
        );
        this.itemCache[term] = this.filteredItems;
      }
      this.updateVisibleItems();
      this.highlightedIndex = -1;
    },
    filterFunction(item, term) {
      if (this.dataType === "courses") {
        return (
          item.course_name.toLowerCase().includes(term) ||
          item.course_code.toLowerCase().includes(term)
        );
      } else if (
        this.dataType === "institutions" ||
        this.dataType === "studyPrograms"
      ) {
        return item.name.toLowerCase().includes(term);
      }
      return false;
    },
    updateVisibleItems() {
      this.visibleItems = this.filteredItems.slice(0, 20);
    },
    formatItem(item) {
      if (this.dataType === "courses") {
        return `${item.course_code} - ${item.course_name}`;
      } else {
        return item.name;
      }
    },
    selectItem(item) {
      this.searchTerm = this.formatItem(item);
      this.$emit("search", item);
      this.filteredItems = [];
      this.visibleItems = [];
      this.highlightedIndex = -1;
    },
    onArrowDown() {
      if (this.highlightedIndex < this.visibleItems.length - 1) {
        this.highlightedIndex++;
      } else {
        this.highlightedIndex = 0;
      }
    },
    onArrowUp() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex--;
      } else {
        this.highlightedIndex = this.visibleItems.length - 1;
      }
    },
    onEnter() {
      if (
        this.highlightedIndex >= 0 &&
        this.highlightedIndex < this.visibleItems.length
      ) {
        this.selectItem(this.visibleItems[this.highlightedIndex]);
      }
    },
    onMouseEnter(index) {
      this.highlightedIndex = index;
    },
  },
};
</script>

<style scoped>
.search-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.search-input {
  padding: 0.75rem;
  margin-right: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.search-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
}

.search-list-item {
  padding: 0.75rem;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  color: #333;
  background-color: #fff;
}

.search-list-item:last-child {
  border-bottom: none;
}

.search-list-item:hover,
.search-list-item-active {
  background-color: #f0f0f0;
  color: #000;
}

@media (max-width: 600px) {
  .search-bar {
    width: 80%;
    padding: 2.5rem;
  }
  .search-input {
    padding: 0.5rem;
  }
  .search-list-item {
    padding: 0.5rem;
  }
}
</style>
